<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
   
    <a class="logo" href="#" (click)="navigateHome()"><img src="../../../../assets/images/LOGO Ledger 24.png" alt="Back"
       class = "img" /></a>
    <a (click)="backClicked()"><img src="../../../../assets/images/back.png" alt="Back" width="30px;"
        height="25px" class = "imgBack" /></a>
        <a class="logo1" href="#" (click)="navigateHome()">Sale Funnel</a>
  </div>

</div>

<!-- <button class="btn ;selected" (click)="goToHome()">
  <i class="fa fa-home" aria-hidden="true"></i>Home
</button> -->

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
    <img [src]="img"  class ="companyLogo" *ngIf="img"/>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" name={{loginId}}>
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<!-- <div class="user_logo">
  <nb-actions size="small">

    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" name={{loginId}}>
      </nb-user>
    </nb-action>
  </nb-actions>
</div> -->